import React, { useEffect, useState } from 'react';
import { Layout, Menu, ConfigProvider, Row, Col, Avatar, Typography, Divider, Button, Spin, Popover } from 'antd';
import {
    UserOutlined,
    LogoutOutlined,
    FundOutlined,
    DashboardOutlined, ThunderboltOutlined, SettingOutlined, ShopOutlined
} from '@ant-design/icons';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, setPathname, setToken } from './dashboardSlice';
import { useCookies } from 'react-cookie';
// import Home from './Home';
// import Profile from './Profile';
import frFR from 'antd/locale/fr_FR';
// import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './dashboard.css'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { BsPersonBoundingBox } from "react-icons/bs"
import { RiFileList3Line, RiAdminLine } from "react-icons/ri"
import { MdOutlineNotificationAdd } from "react-icons/md"
import jwt_decode from "jwt-decode";
import { setredirectToDashboard } from '../signIn/signInSlice';
import { Helmet } from 'react-helmet';
import ActivationCodes from '../activation/ActivationCodes';

const { Text } = Typography;

const { Header, Content, Sider } = Layout;

const headerMenuItems = [];

let siderMenuItems = {
    codes: [
        { key: 'codes', label: 'Vue d\'ensemble', children: [{ key: 'codeList', label: 'Liste des codes' }], icon: <UserOutlined /> },
    ],
};
const Dashboard = () => {
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();
    const [cookies, setCookies, removeCookie] = useCookies();

    const [collapsed, setCollapsed] = useState(false);

    const [helmet, setHelmet] = useState("Cactus");

    const pathname = useSelector((state) => state.dashboard.pathname);
    const fetchProfileResult = useSelector((state) => state.dashboard.fetchProfileResult);
    const isFetchingProfile = useSelector((state) => state.dashboard.isFetchingProfile);
    const token = useSelector((state) => state.dashboard.token);

    useEffect(() => {
        dispatch(setPathname(location.pathname));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    useEffect(() => {
        const pathElements = location.pathname.split('/').filter(Boolean);
        const key = pathElements[0];

        if (key) {
            setHelmet(
                siderMenuItems[key].find((item) => item.key === key).label
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        dispatch(fetchUserProfile(cookies));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = cookies.token;
        if (token) {
            dispatch(setToken(token));
            const decodedToken = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                removeCookie("token", {path: "/"});
                navigate("/signin")

                // Token has expired
            }
        } else {
            navigate("/signin")

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const AvatarProfile = () => {
        return (
            <Avatar
                shape="square"
                size={38}
                style={{ cursor: "pointer", backgroundColor: "#7F7F7F" }}
            >{fetchProfileResult?.user.email[0].toUpperCase()}</Avatar>
        );
    }
    const AvatarLoader = () => {
        return (
            <Spin spinning={true}>
                <Avatar

                />
            </Spin>
        );
    };
    return (
        <ConfigProvider
            locale={frFR}
            theme={{
                token: {
                    colorPrimary: "#39B13D",
                    borderRadius: "4px",
                    fontFamily: "Roboto"
                },
            }}
        >
            <Layout style={{ minHeight: '100vh' }}>
                <Header className="header" style={{ height: "48px", alignItems: "center", backgroundColor: "#424242" }}>
                    <Row style={{ height: "100%", lineHeight: "48px", fontSize: '14px', flexDirection: "row", justifyContent: "end" }}>

                        <Row align="middle" justify="end">
                            <div style={{ marginRight: "24px", display: "flex", alignContent: "center" }} >
                                <Button
                                    icon={<LogoutOutlined />}
                                    color='#39B13D'
                                    style={{ backgroundColor: "#39B13D", border: "none", color: "#fff" }}
                                    onClick={() => {
                                        removeCookie("token", { path: "/" });
                                        navigate("/signin")
                                        dispatch(setredirectToDashboard())
                                    }}
                                >
                                    Déconnexion
                                </Button>
                            </div>
                            <div style={{ marginRight: "24px", display: "flex", alignContent: "center" }} >

                                {isFetchingProfile ? <AvatarLoader /> : <AvatarProfile />}

                            </div>
                            <div style={{ marginRight: "24px", display: "flex", alignContent: "center" }} >
                                <Text style={{ color: "white", fontWeight: 400, fontSize: "18px" }} >{fetchProfileResult?.user.name}</Text>

                            </div>
                        </Row>
                    </Row>
                    <div className="logo" />

                </Header>
                <Layout>
                    <Sider theme="light" width={200} className="site-layout-background" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} >
                        {!collapsed ? (
                            <img
                                src="/logo4.png"
                                alt="logo"
                                style={{ height: "24px", width: "117px", display: "block", margin: "15px 70px 17px 28px", }}
                            />
                        ) :
                            <img
                                src="/C.png"
                                alt="logo"
                                style={{ height: "24px", width: "24px", display: "block", margin: "15px 70px 17px 21px", }}
                            />
                        }
                        <Menu
                            mode="inline"
                            style={{ height: '100%', borderRight: 0 }}

                            selectedKeys={"codeList"}
                            items={siderMenuItems["codes"]}
                            defaultOpenKeys={siderMenuItems["codes"].map((item) => item.key)}
                        >
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content
                            className="site-layout-background"
                            style={{
                                // padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            <>
                                <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>{helmet}</title>
                                </Helmet>

                                < ActivationCodes />
                            </>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </ConfigProvider >
    );
};

export default Dashboard;
