import React, { useState, useEffect } from "react";
import { Layout, Space, DatePicker, Button, Divider, Table, Badge, Input, Slider, Radio, Tooltip, Modal, Select, InputNumber, Spin, List, Typography } from 'antd';
import {
    SendOutlined, ImportOutlined, SearchOutlined,
    FilterFilled, CaretDownFilled, CaretUpFilled,
    UserOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import PageHeader from "../../comps/pageHeader";
import { useDispatch, useSelector } from "react-redux";
import { ExportPDF, exportPDFSuccess, getActivationLinks, getCodes, getCodesCount, getModulesExportOptions, sendActivationCodesEmail } from "./codesSlice";
import { useCookies } from 'react-cookie';
import { ReactMultiEmail } from "react-multi-email";
import 'react-multi-email/dist/style.css';
import './activation.css';
import { sendActivationCodesEmailUrl } from "../../constants";
import axios from "axios";

const { RangePicker } = DatePicker;
const { Header, Footer, Content } = Layout;
const { Link } = Typography;

export default function ActivationCodes({ props }) {

    const dispatch = useDispatch();
    const [cookies, setCookies] = useCookies();

    const [timeout, setTimeoutVal] = useState(0);
    const [codeFilterDisplay, setCodeFilterDisplay] = useState("");
    const [filterData, setFilterData] = useState({
        page: 1,
        docsPerPage: 10,
        startDate: null,
        endDate: null,
        codeFilter: null,
        modulesFilter: null,
        dateSortOrder: -1,
        statusFilter: "both",
        sentFilter: "both"

    });
    const [selectedModulesExport, setSelectedModulesExport] = useState(0);
    const [numberOfCodesToExport, setNumberOfCodesToExport] = useState(1);
    const [selectedModulesSend, setSelectedModulesSend] = useState(0);
    const [numberOfCodesToSend, setNumberOfCodesToSend] = useState(1);

    const [typeOfSend, setTypeOfSend] = useState("1");

    const [selectedCode, setSelectedCode] = useState(null);

    const [emails, setEmails] = useState([]);




    useEffect(() => {
        dispatch(getCodesCount(cookies));
        dispatch(getCodes(cookies, filterData));
        dispatch(getModulesExportOptions(cookies));
        dispatch(getActivationLinks(cookies));
    }, [filterData]);

    const CodeCountResult = useSelector((state) => state.codes.fetchCodeCountsResult);
    const CodeListResult = useSelector((state) => state.codes.fetchCodesResult);
    const isLoadingList = useSelector((state) => state.codes.isFetchingCodes);
    const ModuleOptions = useSelector((state) => state.codes.fetchModuleOptionsResult);
    const ExportResponse = useSelector((state) => state.codes.exportPDFResult);
    const isExportingPDF = useSelector((state) => state.codes.isExportingPDF);
    const ActivationLinks = useSelector((state) => state.codes.activationLinksResult);
    const IsSendingEmails = useSelector((state) => state.codes.isFetchingSendEmails);


    const sendCode = (e) => {
        setSelectedCode(e.currentTarget.dataset.id);
        showEmailModal();
    }

    const sendCodes = () => {
        let body = {
            modules: ModuleOptions.options[selectedModulesExport].option,
            emails,
            isMultiple: typeOfSend === "2",
            singleCodeId: selectedCode
        };
        if (typeOfSend === "1") {
            body.count = numberOfCodesToSend;
        }
        else {
            body.count = emails.length;
        }
        dispatch(sendActivationCodesEmail(cookies, body))
    }

    useEffect(() => {
        if (!ExportResponse) {
            return;
        }
        window.open(ExportResponse.fileLink, '_blank', 'noopener,noreferrer');
        setIsPDFModalOpen(false);

        dispatch(getCodesCount(cookies));
        dispatch(getCodes(cookies, filterData));
        dispatch(getModulesExportOptions(cookies));
        dispatch(getActivationLinks(cookies))

        dispatch(exportPDFSuccess(null));

    }, [ExportResponse]);

    const onChangeModuleToExport = (e) => {
        console.log(e);
    }

    const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

    const showPDFModal = () => {
        setIsPDFModalOpen(true);
    };

    const handlePDFOk = () => {
        dispatch(ExportPDF(cookies, { modules: ModuleOptions.options[selectedModulesExport].option, numberOfCodes: numberOfCodesToExport }))
    };

    const handlePDFCancel = () => {
        setIsPDFModalOpen(false);
    };

    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    const showEmailModal = () => {
        setIsEmailModalOpen(true);
    };

    const handleEmailOk = () => {
        console.log("nikmok")
        sendCodes();
        setIsEmailModalOpen(false);
    };

    const handleEmailCancel = () => {
        setIsEmailModalOpen(false);
    };

    const [isLinksModalOpen, setIsLinksModalOpen] = useState(false);

    const showLinksModal = () => {
        setIsLinksModalOpen(true);
    };

    const handleLinksOk = () => {
        setIsLinksModalOpen(false);
    };

    const handleLinksCancel = () => {
        setIsLinksModalOpen(false);
    };

    const timedSearch = (data) => {
        setCodeFilterDisplay(data);
        let baseText = data;
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            let text = baseText ? baseText.replaceAll("-", "") : null;
            setFilterData({ ...filterData, codeFilter: text });
        }, 300));
    }

    const onChangePage = (e) => {
        setFilterData({ ...filterData, page: e.current, docsPerPage: e.pageSize });
    }

    const onChangeDates = (e) => {
        if (e) {
            setFilterData({ ...filterData, startDate: e[0]?.format(), endDate: e[1]?.format() });
        }
        else {
            setFilterData({ ...filterData, startDate: null, endDate: null });
        }
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Tooltip placement="top" title={"Veuillez insérer un code complet"}>
                    <Input
                        // ref={searchInput}
                        placeholder={`XXXX-XXXX-XXXX-XXXX`}
                        value={codeFilterDisplay}
                        onChange={(e) => timedSearch(e.target.value)}

                        //   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                </Tooltip>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getCodes(cookies, filterData))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setFilterData({ ...filterData, codeFilter: null }); setCodeFilterDisplay(null) }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnFilterByModuleProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Slider
                    range
                    defaultValue={[1, 3]}
                    min={1}
                    max={6}
                    value={filterData.modulesFilter ? filterData.modulesFilter : [1, 1]}
                    onChange={(e) => setFilterData({ ...filterData, modulesFilter: e })}
                    marks={{ 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6" }}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        marginBottom: "24px"
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getCodes(cookies, filterData))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => setFilterData({ ...filterData, modulesFilter: null })}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnSortByDateProps = (dataIndex) => ({
        filterDropdown: (<></>),
        filterIcon: (filtered) => (<>
            {filterData.dateSortOrder === 1 ?
                <CaretUpFilled
                    onClick={() => setFilterData({ ...filterData, dateSortOrder: -1 })}
                    style={{
                        color: '#39B13D',
                    }}
                /> :
                <CaretDownFilled
                    onClick={() => setFilterData({ ...filterData, dateSortOrder: 1 })}
                    style={{
                        color: '#39B13D',
                    }}
                />}
        </>),
    });

    const getColumnFilterStatusProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setFilterData({ ...filterData, statusFilter: e.target.value })} value={filterData.statusFilter}>
                    <Space direction="vertical">
                        <Radio value={"1"}>Non Activés</Radio>
                        <Radio value={"0"}>Activés</Radio>
                        <Radio value={"both"}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const getColumnFilterSentProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setFilterData({ ...filterData, sentFilter: e.target.value })} value={filterData.sentFilter}>
                    <Space direction="vertical">
                        <Radio value={"false"}>Pas Envoyés</Radio>
                        <Radio value={"true"}>Envoyés</Radio>
                        <Radio value={"both"}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const columns = [
        {
            title: 'Code d\'accès',
            dataIndex: 'code',
            key: '_id',
            render: (text) => (<p>{text.match(/(.{4})/g).join("-")}</p>),
            width: "800px",
            ...getColumnSearchProps('code')
        },
        {
            title: 'Modules',
            dataIndex: 'modules',
            key: '_id',
            render: (data) => (<p>{data[0]}-{data[1]} </p>),
            width: "20px",
            ...getColumnFilterByModuleProps('modules')
        },
        {
            title: 'Date reçu',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data) => (<p>{dayjs(data).format("DD/MM/YYYY")}</p>),
            width: "30px",
            ...getColumnSortByDateProps('createdAt')

        },
        {
            title: 'Envoi',
            dataIndex: 'sent',
            key: 'sent',
            render: (data) => (<Badge color={data ? "#52C41A" : "#FAAD14"} text={data ? "Envoyé" : "Pas Envoyé"} />),
            width: "100px",
            ...getColumnFilterSentProps('sent')
        },
        {
            title: 'État',
            dataIndex: 'usesLeft',
            key: 'usesLeft',
            render: (data) => (<Badge color={data === 0 ? "#52C41A" : "#FAAD14"} text={data === 0 ? "Activé" : "Non Activé"} />),
            width: "100px",
            ...getColumnFilterStatusProps('sent')
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (data) => (<a data-id={data} onClick={sendCode} style={{ color: "#39B13D", fontSize: "16px" }}>Envoyer</a>),
            width: "40px"

        }

    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
            <Layout>
                <PageHeader title={"Vos codes d'accès"} breadcrumbItems={["Vue d'ensemble", "Liste des codes d'accès"]} />
                <Content >
                    {/* Upper half of the page, basic code data and interaction buttons */}
                    <div style={{
                        margin: "2em",
                        padding: "2em 15em 2em 15em",
                        display: "flex",
                        direction: "column",
                        justifyContent: "space-between",
                        backgroundColor: "#fff"
                    }}>
                        <div style={{ flexDirection: "column", justifyContent: "start" }}>
                            <h3 style={{ fontSize: "14px", color: "#00000073" }}>Codes Non Envoyés</h3>
                            <h1 style={{ fontSize: "24px", margin: "5px 5px 5px 0px" }}>{CodeCountResult ? CodeCountResult.unsentCount : "NaN"}</h1>
                        </div>
                        <div style={{ flexDirection: "column", justifyContent: "start" }}>
                            <h3 style={{ fontSize: "14px", color: "#00000073" }}>Codes Non Activés</h3>
                            <h1 style={{ fontSize: "24px", margin: "5px 5px 5px 0px" }}>{CodeCountResult ? CodeCountResult.unusedCount : "NaN"}</h1>
                        </div>
                        <div style={{ display: "flex", direction: "row" }}>
                            <div style={{ marginRight: "20px" }}>
                                <p style={{ color: "#000000d9", fontWeight: "600" }}>Exporter vos codes en PDF :</p>
                                <Button
                                    icon={<ImportOutlined dir="left" style={{ fontSize: "16px" }} />}
                                    style={{
                                        backgroundColor: "#39B13D",
                                        fontSize: "16px",
                                        color: "#fff",
                                        height: "2.3em",
                                        width: "220px",
                                        fontWeight: "400"
                                    }}
                                    onClick={() => showPDFModal()}
                                >
                                    Exporter
                                </Button>
                            </div>
                            <div>
                                <p style={{ color: "#000000d9", fontWeight: "600" }}>Envoyer un ou plusieurs codes :</p>
                                <Button
                                    icon={<SendOutlined style={{ fontSize: "16px" }} />}
                                    style={{
                                        backgroundColor: "#39B13D",
                                        fontSize: "16px",
                                        color: "#fff",
                                        height: "2.3em",
                                        width: "220px",
                                        fontWeight: "400"
                                    }}
                                    onClick={() => showEmailModal()}
                                >
                                    Envoyer
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "2em",
                            padding: "1em 2em 1em 2em",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: "#fff"
                        }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            <h3>Liste des Codes d’Accès</h3>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center"
                            }}>
                                <Button
                                    // icon={<ImportOutlined dir="left" style={{ fontSize: "16px" }} />}
                                    style={{
                                        backgroundColor: "#39B13D",

                                        color: "#fff",
                                        height: "2.3em",
                                        width: 160,
                                        fontWeight: "400"
                                    }}
                                    onClick={() => showLinksModal()}
                                >Liens d'activation</Button>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center"
                            }}>
                                <p style={{ marginRight: "10px", fontSize: "15px" }}>Filtrer par date :</p>
                                <RangePicker
                                    style={{
                                        height: "2.5em"
                                    }}
                                    onCalendarChange={onChangeDates}
                                />
                            </div>
                        </div>
                        <Divider />
                        <Table
                            loading={isLoadingList}
                            dataSource={CodeListResult?.codes}
                            pagination={{
                                total: CodeListResult?.nCodes,
                                pageSize: filterData.docsPerPage,
                                showSizeChanger: true,
                                pageSizeOptions: [10, 15, 20, 30, 40, 50],
                                showQuickJumper: true,
                                showTotal: (total, range) => (<>Nombre de codes : {total} </>),
                            }}
                            columns={columns}
                            onChange={onChangePage}
                        />

                        <Modal title="Exporter sous format PDF" open={isPDFModalOpen} onOk={handlePDFOk} okText={isExportingPDF ? <Spin /> : "Exporter"} onCancel={handlePDFCancel}>
                            <Divider style={{ margin: "18px 0px 18px 0px" }} />
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Codes d'Accès :</p>
                                <Select
                                    style={{
                                        width: 250,
                                        height: "100%"
                                    }}
                                    value={selectedModulesExport}
                                    options={ModuleOptions?.options.map((elem, index) => { return { value: index, label: `Modules de ${elem.option[0]} à ${elem.option[1]} (${elem.count} restants)` } })}
                                    onChange={(e) => setSelectedModulesExport(e)}
                                />
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "8px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Exporter :</p>
                                <InputNumber
                                    style={{
                                        width: 70
                                    }}
                                    max={ModuleOptions?.options[selectedModulesExport]?.count}
                                    min={1}
                                    value={numberOfCodesToExport}
                                    onChange={(e) => { console.log(e); setNumberOfCodesToExport(e) }}
                                />
                                <p style={{ marginLeft: "8px" }}>codes</p>
                            </div>
                        </Modal>
                        <Modal title="Envoyer un ou plusieurs Codes" open={isEmailModalOpen} okText={IsSendingEmails ? <Spin /> : "Envoyer"} onOk={handleEmailOk} onCancel={handleEmailCancel}>
                            <Divider style={{ margin: "18px 0px 18px 0px" }} />
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Codes d'Accès :</p>
                                <Select
                                    style={{
                                        width: 250,
                                        height: "100%"
                                    }}
                                    value={selectedModulesSend}
                                    options={ModuleOptions?.options.map((elem, index) => { return { value: index, label: `Modules de ${elem.option[0]} à ${elem.option[1]} (${elem.count} restants)` } })}
                                    onChange={(e) => setSelectedModulesSend(e)}
                                />
                            </div>
                            {typeOfSend == "1" && (<div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "8px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Envoyer :</p>
                                <InputNumber
                                    style={{
                                        width: 70
                                    }}
                                    max={ModuleOptions?.options[selectedModulesSend]?.count}
                                    min={1}
                                    value={numberOfCodesToSend}
                                    onChange={(e) => { console.log(e); setNumberOfCodesToSend(e) }}
                                />
                                <p style={{ marginLeft: "8px" }}>codes</p>
                            </div>)}
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "16px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Type d'envoi :</p>
                                <Radio.Group onChange={(e) => setTypeOfSend(e.target.value)} value={typeOfSend} defaultValue={"1"}>
                                    <Space direction="horizontal">
                                        <Radio value={"1"}>Un seul destinataire</Radio>
                                        <Radio value={"2"}>Plusieurs destinataires</Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: "16px"
                            }}>
                                <p style={{ margin: "0px 16px 0px 0px" }}>Envoyer à : </p>
                                {typeOfSend == "1" ? (<Input
                                    placeholder={`johndoe@email.com`}
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    value={emails[0]}
                                    onChange={(e) => setEmails([e.target.value])}

                                    style={{
                                        marginBottom: 8,
                                        width: 350,
                                    }}
                                />) :
                                    (<ReactMultiEmail
                                        emails={emails}
                                        onChange={(emails) => { setEmails(emails); console.log(emails); }}
                                        style={{
                                            marginBottom: 8,
                                            width: 350,
                                        }}
                                        getLabel={(email, index, removeEmail) => {
                                            return (
                                              <div data-tag key={index}>
                                                <div data-tag-item>{email}</div>
                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                  ×
                                                </span>
                                              </div>
                                            );
                                          }}
                                    ></ReactMultiEmail>)
                                }
                            </div>

                        </Modal>
                        <Modal title="Mes liens d'activation Cactus" open={isLinksModalOpen} onOk={handleLinksOk} onCancel={handleLinksCancel}>
                            <Divider style={{ margin: "18px 0px 18px 0px" }} />
                            <List dataSource={ActivationLinks?.links} renderItem={(item) => (
                                <List.Item style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                    <h4 style={{ margin: "0px" }}>Modules de {item.split("__")[1][0]} à {item.split("__")[1][1]} </h4>
                                    <Link href={item} copyable>{item}</Link>
                                </List.Item>
                            )} />

                        </Modal>
                    </div>
                </Content>
            </Layout>
        </Space>
    )
}


