import { Row, Col, Typography, Breadcrumb } from 'antd';


const { Title } = Typography;



const PageHeader = ({ title, breadcrumbItems }) => {
    return (
        <div
            style={{
                background: "white",
                minHeight: 100,
                width: "100%",
                boxShadow: "inset 10px 3px 10px -10px #00000055"
            }}
        >
            <Row style={{ padding: "16px 0px 24px 24px" }}>
                <Col span={20}>

                    <Breadcrumb style={{ margin: "16px 0", fontSize: "14px" }}>
                        {breadcrumbItems.map((item,idex) =>
                            <Breadcrumb.Item key={idex}>{item}</Breadcrumb.Item>
                        )}

                    </Breadcrumb>
                    <Title level={4} >{title}</Title>
                </Col>
                <Col>
                </Col>
            </Row>
        </div>
    );
}

export default PageHeader;