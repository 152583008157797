import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { profile_url } from "../../constants";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    fetchProfileResult: null,
    fetchProfileError: null,
    isFetchingProfile: false,
    token: null,
    currentMenuItem:"codes"
  },
  reducers: {
    setPathname: (state, action) => {
      state.pathname = action.payload;
    },
    setCurrentMenuItem: (state, action) => {
      state.currentMenuItem = action.payload;
    },
    setProfileResult: (state, action) => {
      state.fetchProfileResult = action.payload;
      state.isFetchingProfile = false;
    },
    setProfileError: (state, action) => {
      state.fetchProfileError = action.payload;
      state.isFetchingProfile = false;
    },
    setFetchingProfile: (state, action) => {
      state.isFetchingProfile = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    }
  },
});

export const {
  setPathname,
  setProfileResult,
  setProfileError,
  setCurrentMenuItem,
  setFetchingProfile,
  setToken
} = dashboardSlice.actions;

export const fetchUserProfile = (cookies) => (dispatch, getState) => {
  const config = {
    method: "post",
    url: profile_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: cookies.token,
    },
  };
  
  dispatch(setFetchingProfile(true));
  const response = axios(config)
    .then((response) => {
      dispatch(setProfileResult(response.data));
    })
    .catch((response) => {
      dispatch(setProfileError(response.message));
    });

  
  return response.data;
};

// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// export const selectCount = (state) => state.dashboard.value;

export default dashboardSlice.reducer;
